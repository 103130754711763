import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';

import { __ } from '../../i18n';

@graphql(gql`mutation deleteCommitment($deleteCommitmentMutation: DeleteCommitmentInput!) {
  deleteCommitment(input: $deleteCommitmentMutation) {
    clientMutationId
  }
}`, {
  options: {
  refetchQueries: ['MessageNodeEntityQuery', 'MessageNodeOrganizationQuery', 'MessageCommitmentRemindsEntityQuery', 'MessageCommitmentRemindsOrganizationQuery']
  }
  })
@inject('store') @observer
export default class CommitmentDelete extends Controller {
  request = () => this.props.mutate({
    variables: {
      deleteCommitmentMutation: {
        id: this.props.commitment.dbId
      }
    }
  }).then(() => {
    this.props.store.snackbar = { active: true, message: __('Commitment was deleted'), success: true };
    this.props.store.appends.pop();
  })

  render() {
    return (
      <Controller
        id="CommitmentDelete"
        delete
        object={__('commitment')}
        onSubmit={this.onSubmit}
        onCancel={() => this.props.store.appends.pop()}
        {...this.props}
      />
    );
  }
}
