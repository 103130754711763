import React from 'react';
import { inject, observer } from 'mobx-react';
import { Container, Card, Icon, Dropdown, Popup } from 'semantic-ui-react';
import gql from 'graphql-tag';
import moment from 'moment';

import CommitmentReminds from './Reminds';

import { __ } from '../../i18n';
import * as utils from '../../utils';

import Modal from '../../components/ui/Modal';
import Responsive from '../../components/Responsive';
import OptionsToast from '../../components/OptionsToast';

@inject('store') @observer
export default class CommitmentItem extends Responsive {
  static fragments = {
    commitment: gql`
      fragment CommitmentItem on Commitment {
        id: dbId
        date
        time
        name
        noConfirm
        expired
        dateLimit
        deleted
        videocallType
        videocallLink
        summary {
          count
        }
        ...CommitmentReminds
    }
      ${CommitmentReminds.fragments.commitment}
    `,
  }

  renderLimitDate = (commitment) => {
    const commitmentDateLimit = String(commitment.dateLimit);
    const formattedDate = new Date(commitmentDateLimit.substring(0, 15).split('-'));

    if (
      commitment.date && commitment.time &&
      commitment.date.getDate() === formattedDate.getDate() &&
      commitment.date.getMonth() === formattedDate.getMonth() &&
      commitment.date.getFullYear() === formattedDate.getFullYear()
    ) {
      formattedDate.setHours(parseInt(commitment.time.split(':')[0], 10));
      formattedDate.setMinutes(parseInt(commitment.time.split(':')[1], 10));
    } else {
      formattedDate.setHours(23);
      formattedDate.setMinutes(59);
      formattedDate.setSeconds(59);
    }

    const lang = this.props.store.app.locale && this.props.store.app.locale.substring(0, 2);
    return utils.simpleDate(formattedDate, true, 'LL, HH:mm', lang);
  }

  renderMenu = () => {
    const { isMessageToApprove } = this.props;

    const menu = [
      !isMessageToApprove && { dataAction: 'edit', text: __('Edit'), icon: 'edit', color: '#000000', closeOnClick: true, onClick: () => { this.props.onEdit(); } },
      { dataAction: 'delete', text: __('Delete Commitment'), icon: 'trash', color: '#BF2600', closeOnClick: true, onClick: () => { this.props.onDelete(); } },
      { dataAction: 'cancel', text: __('Cancel'), icon: 'times', color: '#000000', closeOnClick: true },
    ];
    return (
      this.props.store.appends.push(<Modal
        id="OptionsToast"
        toast
        invertCloseButton
        onClose={() => this.props.store.appends.pop()}
        closeOnRootNodeClick
        header={__('Commitment Options')}
        spaceFooter
        content={<OptionsToast menu={menu} />}
      />));
  }


  render() {
    const { commitment, id, isMessageToApprove } = this.props;
    const { time, name } = commitment;

    let date = null;
    let formattedDate = null;

    if (commitment.date) {
      const commitmentDate = String(commitment.date);
      const then = moment(commitmentDate);
      const d = then.format('dddd, LL'); //splitting for translation purposes
      date = (<div>
        {d}{time && ', ' + time.substring(0, 5)}
      </div>);

      formattedDate = new Date(commitmentDate.substring(0, 10).split('-'));

      if (commitment.time) {
        formattedDate.setHours(parseInt(commitment.time.split(':')[0], 10));
        formattedDate.setMinutes(parseInt(commitment.time.split(':')[1], 10));
      } else {
        formattedDate.setHours(23);
        formattedDate.setMinutes(59);
        formattedDate.setSeconds(59);
      }
    }

    let items = [];

    items = items.concat([
      !isMessageToApprove && <Dropdown.Item
        onClick={() => this.props.onEdit()}
      >
        {__('Edit')}
      </Dropdown.Item>,
      <Dropdown.Item onClick={() => this.props.onDelete()}>
        {__('Delete')}
      </Dropdown.Item>
    ]);

    return (
      <Card
        data-type="CommitmentItem"
        data-index={id}
        fluid
        style={{
          marginBottom: '1em',
          width: this.isMobile() ? '100%' : '355px',
          height: this.isMobile() ? '' : '159px',
          borderRadius: '12px',
          marginRight: '8px'
        }}
      >
        <Card.Content style={{ margin: 0 }}>
          <Container textAlign="center">
            <div className="horizontallySpacedItems" style={{ margin: this.isMobile() ? '22px 10px 14px' : '22px 24px 14px' }}>
              <div className="bold"><Icon name="calendar" style={{ color: '#000000', marginRight: '12px', fontSize: '16px', fontWeight: 300 }} />
                <Popup
                  trigger={<span>{(name.length > 25) ? (name.substring(0, 25) + '...') : name}</span>}
                  content={<span>{name}</span>}
                  hideOnScroll
                />
              </div>
              {
                (items.length > 0) ?
                  !this.isMobile() ?
                    <Dropdown data-testid="commitments-edit-menu" icon={null} trigger={<Icon name="ellipsis v" style={{ margin: 0 }} />}>
                      <Dropdown.Menu className="bold">
                        {items}
                      </Dropdown.Menu>
                    </Dropdown>
                    :
                    <Icon data-testid="commitments-edit-menu" name="ellipsis v" onClick={() => this.renderMenu()} style={{ margin: 0 }} />
                  :
                  null
              }
            </div>
            <div id="CommitmentDate" style={{ margin: this.isMobile() ? '0 18px' : '0 32px', marginBottom: commitment.dateLimit ? '14px' : '24px' }}>
              <div
                style={{
                  borderRadius: '200px',
                  backgroundColor: '#ffffff',
                  display: 'table',
                  width: '100%',
                  border: date ? '1px solid #084FFF99' : '1px solid #B7B7B7'
                }}
              >
                <p style={{ fontSize: '13px', fontWeight: '700', padding: '12px', color: date ? '#084FFF' : '#B7B7B7', display: 'table-cell', verticalAlign: 'middle' }}>{date || __('No date specified')}</p>
              </div>
            </div>
            {
              commitment.dateLimit && !commitment.noConfirm &&
              <p style={{ color: '#ffb43c', fontSize: '13px', fontWeight: 300, marginBottom: '24px' }}>
                <Icon name="exclamation circle" />
                {
                  __('Confirmations until %s', this.renderLimitDate(commitment))
                }
              </p>
            }
          </Container>
        </Card.Content>
      </Card>
    );
  }
}
