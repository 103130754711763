import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Popup, Label, Table } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { __ } from '../../i18n';
import * as utils from '../../utils';

import Modal from '../../components/Modal';
import TableView from '../../components/TableView';


import EntityItem from '../Entity/Item';

const PAGE_SIZE = 40;

@inject('store') @observer
@graphql(gql`query CommitmentRemindsQuery($id: ID!, $limit: Int, $offset: Int) {
    node(id: $id) @connection(key: "Commitment", filter: ["id"]) {
      ... on Commitment {
        id: dbId
        reminds (limit: $limit, offset: $offset) {
          totalCount
          nodes {
            created
            entity {
              id: dbId
              fullname
              picture {
                uri
                id: dbId
                key
              }
              roles {
                nodes {
                  name
                }
              }
            }
            user {
              fullname
            }
          }
          pageInfo {
            hasNextPage
          }
        }
      }
    }
  }
`, {
  options: ownProps => ({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      id: ownProps.commitment.dbId,
      limit: PAGE_SIZE,
      offset: 0
    }
  })
})
export default class CommitmentReminds extends React.Component {
  static fragments = {
    commitment: gql`
      fragment CommitmentReminds on Commitment {
        dbId
      }
      `,
  }

  loadMore = () => {
    const { data } = this.props;

    if (!data.node.reminds.pageInfo.hasNextPage) return;

    data.fetchMore({
      variables: {
        offset: data.node.reminds.nodes.length
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        // Don't do anything if there weren't any new items
        if (!fetchMoreResult || fetchMoreResult.node.reminds.nodeslength === 0) {
          return previousResult;
        }

        fetchMoreResult.node.reminds.nodes =
          [...previousResult.node.reminds.nodes, ...fetchMoreResult.node.reminds.nodes];

        return fetchMoreResult;
      },
    });
  }

  renderGroup = (name) => {
    if (name.length <= 10) return <span key={1}>{name}</span>;

    return (
      <Popup
        basic
        hideOnScroll
        key={0}
        content={<div>{name}</div>}
        trigger={<span>{name.substring(0, 10).trim()}...</span>}
      />
    );
  }

  renderColumns = () => [
    __('User'),
    __('Group'),
    __('Date')
  ]

  renderRowCells = (remind) => {
    const { store } = this.props;
    const lang = store.app.locale ? (store.app.locale === 'pt' ? 'pt-BR' : store.app.locale) : 'en';

    return ([
      <div>
        <EntityItem entity={remind.entity} user={remind.user} limit fromRemind />
      </div>,
      <div>
        {
          remind.entity.roles.nodes && remind.entity.roles.nodes.length ?
            (
              remind.entity.roles.nodes.length === 1 ?
                remind.entity.roles.nodes.map(role => this.renderGroup(role.name))
                :
                [
                  this.renderGroup(remind.entity.roles.nodes[0].name),
                  <Popup
                    basic
                    on="click"
                    key={1}
                    content={<div>{remind.entity.roles.nodes.slice(1).map(role => <div style={{ padding: '5px' }}>{role.name}</div>)}</div>}
                    trigger={<Label circular as={Button} style={{ marginLeft: '10px' }}>+{remind.entity.roles.nodes.length - 1}</Label>}
                  />
                ]
            )
            :
            null
        }
      </div>,
      utils.simpleDate(remind.created, true, 'DD MMM YYYY', lang)
    ]);
  };

  renderRows = (row, i) => (
    <Table.Row key={i} data-id={row.id || row.code}>
      {
        this.renderRowCells(row, i).map((cell, j) => <Table.Cell key={j} collapsing={j === 2}>{cell}</Table.Cell>)
      }
    </Table.Row>
  )

  render() {
    const { store, commitment, data } = this.props;

    const exportLink = `${store.app.url}/csv/commitment/reminds?commitment_id=${commitment.dbId}&access_token=${encodeURIComponent(store.access_token)}${data && data.node ?
      '&limit=' + data.node.reminds.totalCount : ''}&tz_offset=${-(new Date().getTimezoneOffset())}`;

    if ((data.loading && !data.node) || data.error || !data.node) return <Modal loading />;

    const { reminds } = data.node;

    return (
      <Modal id="CommitmentReminds" open size="small" closeIcon="times close" onClose={() => this.props.store.appends.pop()}>
        <Modal.Header>
          {__('Commitment Details')}
        </Modal.Header>
        <Modal.Content style={{ overflow: 'scroll', maxHeight: '480px' }}>
          <TableView
            celled
            source={reminds && reminds.nodes}
            renderColumns={this.renderColumns()}
            renderRows={this.renderRows}
            pagination={reminds && reminds.pageInfo && reminds.pageInfo.hasNextPage ? {
              graphql: true,
              hasNextPage: reminds.pageInfo.hasNextPage,
              loading: data.loading
            } : null
            }
            onLoadMore={this.loadMore}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            data-action="export-commitment-reminds"
            icon="cloud download"
            content={__('Export Results')}
            as="a"
            href={exportLink}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
