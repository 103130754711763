import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';
import Modal from '../../components/Modal';

import CommitmentForm from './Form';

import { __ } from '../../i18n';
import * as utils from './../../utils';

@inject('store')
@graphql(gql`query CommitmentEditQuery ($id: ID!) {
    node(id: $id) @connection(key: "Commitment", filter: ["id"]) {
      ... on Commitment {
        id: dbId
        name
        date
        time
        noConfirm
        dateLimit
        videocallLink
      }
    }
  }
`, {
  options: ownProps => ({
    variables: {
      id: ownProps.commitment.dbId
    }
  })
})
@graphql(gql`mutation updateCommitment($updateCommitmentMutation: UpdateCommitmentInput!) {
  updateCommitment(input: $updateCommitmentMutation) {
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['MessageNodeEntityQuery', 'MessageNodeOrganizationQuery', 'MessageCommitmentRemindsEntityQuery', 'MessageCommitmentRemindsOrganizationQuery', 'MessageEntityHistoryQuery', 'MessageOrganizationHistoryQuery']
  }
})
@observer
export default class CommitmentEdit extends Controller {
  request = (values) => {
    const { store, mutate, commitment } = this.props;
    const params = { name: values.name, noConfirm: values.noConfirm, videocallLink: values.videocallLink, videocallType: values.videocallType };

    if (values.hasDate) {
      params.date = new Date(values.date.getTime() - (values.date.getTimezoneOffset() * 60000)).toISOString().substring(0, 10);

      if (values.time) {
        params.time = values.time;
      }
    }

    if (values.noConfirm) {
      values.dateLimit = null;
    } else {
      const time = values.time ? values.time.split(':').map(t => parseInt(t, 10)) : values.time;
      let timeLimit = '23:59';

      if (time && values.date.getDate() === values.dateLimit.getDate() && values.date.getMonth() === values.dateLimit.getMonth() &&
        values.date.getFullYear() === values.dateLimit.getFullYear()) {
        timeLimit = values.time;
      }

      params.dateLimit = new Date(values.dateLimit.getTime() - (values.dateLimit.getTimezoneOffset() * 60000)).toISOString().substring(0, 10) + ' ' + timeLimit;
    }

    if (values.videocallLink) {
      params.videocallLink = utils.castToValidURL(values.videocallLink);
      params.videocallType = values.videocallType;
    } else {
      params.videocallLink = null;
      params.videocallType = null;
    }


    return mutate({
      variables: {
        updateCommitmentMutation: {
          id: commitment.dbId,
          ...params
        }
      }
    }).then(() => {
      store.snackbar = { active: true, message: __('%s was saved', values.name), success: true };
      store.appends.pop();
    });
  }

  render() {
    const { data, store } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Modal loading />;

    const { name, date, time, noConfirm, dateLimit, hasvideocallLink, videocallLink, videocallType } = data.node;

    let formatedDate = null;
    let formatedDateLimit = null;
    let hasvcLink = false;

    if (date) {
      formatedDate = new Date(date);
      formatedDate = new Date(formatedDate.getTime() + (formatedDate.getTimezoneOffset() * 60000));
    }

    if (dateLimit) {
      formatedDateLimit = new Date(dateLimit.substring(0, 10));
      formatedDateLimit = new Date(formatedDateLimit.getTime() + (formatedDateLimit.getTimezoneOffset() * 60000));
    }

    if (videocallLink) {
      hasvcLink = true;
    }

    return (
      <Controller
        id="CommitmentEdit"
        modal={{
          fullScreen: this.isMobile(),
          header: this.isMobile() ? null : __('Edit commitment'),
          portalHeader: this.isMobile()
        }}
        modalType={'beta'}
        edit
        title={__('Edit commitment')}
        form={CommitmentForm}
        submitButton={{ text: __('Save'), isActionButtom: true }}
        cancelButton={{ text: __('Cancel'), isActionButtom: true }}
        values={{
          name,
          date: formatedDate,
          time,
          hasDate: !!date,
          hasTime: !(time === null || time === undefined),
          noConfirm,
          dateLimit: formatedDateLimit || (noConfirm ? null : formatedDate),
          hasvideocallLink: hasvcLink,
          videocallLink,
          videocallType,
        }}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        onCancel={() => store.appends.pop()}
        onClose={() => store.appends.pop()}
        {...this.props}
      />
    );
  }
}
