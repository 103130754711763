import React from 'react';
import { Segment, Icon, Popup, Label } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

import Form from '../../components/ui/Form';
import Calendar from '../../components/Calendar';
import DatePicker from '../../components/DatePicker';
import ColoredCheckbox from '../../components/ColoredCheckbox';
import BetaButton from '../../components/ui/Button';
import BetaModal from '../../components/ui/Modal';
import { __ } from '../../i18n';
import * as utils from '../../utils';

const timeOptions = {
  hour: ['07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18',
    '19', '20', '21', '22', '23', '00', '01', '02', '03', '04', '05', '06'].map(v => ({ text: v, value: v })),
  minute: ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'].map(v => ({ text: v, value: v }))
};

const date = new Date();
const roundedDate = utils.roundDate(date);
const defaultTime = `${('0' + roundedDate.getHours()).slice(-2)}:${('0' + roundedDate.getMinutes()).slice(-2)}`;

const styles = {
  checkbox: {
    margin: '24px 0px 24px 0px',
    display: 'inline-block',
    justifyContent: 'space-between',
  },
  checkboxMobile: {
    justifyContent: 'space-between',
  }
};

@inject('store') @observer
export default class CommitmentForm extends Form {
  defaultValues = {
    name: '',
    date: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
    time: defaultTime,
    endDate: null,
    endTime: null,
    hasDate: true,
    hasTime: true,
    noConfirm: true,
    dateLimit: null,
    hasvideocallLink: false,
    videocallLink: null,
    videocallType: null,
  }

  rules = {
    name: ['required', values => this.validateContent('name', values)],
    dateLimit: [values => this.validateContent('dateLimit', values)],
    hasvideocallLink: [values => this.validateContent('hasvideocallLink', values)],
  }

  maybes = {
    date: ['required', values => this.validateContent('date', values)],
    time: [(values => this.validateContent('time', values)), (values => (values != null && this.isToday(new Date())))]
  }

  isToday = today => (this.state.values.date.getMonth() === today.getMonth() && this.state.values.date.getDate() === today.getDate())

  validateContent = (name, values) => {
    if (name === 'name') {
      return values.trim().length > 0;
    }
    if (name === 'time') {
      const selectedHour = parseFloat(values.split(':')[0]);
      const selectedMinute = parseFloat(values.split(':')[1]);
      const today = new Date();
      const condition = (selectedHour === today.getHours() ? selectedMinute < today.getMinutes() : true);
      if (selectedHour <= today.getHours() && condition) {
        throw new Error(__('You can\'t choose a date that has passed.'));
      }
      return true;
    }
    if (name === 'dateLimit') {
      const { date: stateDate } = this.state.values;
      if (!!values && !!stateDate && (new Date(stateDate.getFullYear(), stateDate.getMonth(), stateDate.getDate()) < new Date(values.getFullYear(), values.getMonth(), values.getDate()))) {
        throw new Error(__('Confirmation date must come before commitment date'));
      }
      return true;
    }

    if (name === 'hasvideocallLink') {
      if (this.state.values.videocallLink) {
        let videocallLink = this.state.values.videocallLink;
        videocallLink = utils.castToValidURL(videocallLink);

        if (values && !videocallLink) {
          throw new Error(__('Link is not defined'));
        }
        if (values && !utils.isUrl(videocallLink)) {
          throw new Error(__('Link is invalid'));
        }
      }
      if (values && !this.state.values.videocallLink) {
        throw new Error(__('Link is not defined'));
      }

      return true;
    }

    return this.state.values.hasDate && !this.state.values.date;
  }

  onHasDateChange = () => {
    this.setValue('hasDate', !this.state.values.hasDate, () => {
      if (this.state.values.hasDate) {
        this.setValue('date', new Date(date.getFullYear(), date.getMonth(), date.getDate()));
        this.setValue('time', defaultTime);
        this.setValue('dateLimit', new Date(date.getFullYear(), date.getMonth(), date.getDate()));
      } else {
        this.setValue('date', null);
        this.setValue('time', null);
        this.setValue('endDate', null);
        this.setValue('endTime', null);
      }
    });
  }

  onHasTimeChange = () => {
    this.setValue('hasTime', !this.state.values.hasTime, () => {
      if (this.state.values.hasTime) {
        this.setValue('time', defaultTime);
      } else {
        this.setValue('time', null);
        this.setValue('endDate', null);
        this.setValue('endTime', null);
      }
    });
  }

  getTime = (where, name) => {
    const time = name === 'end' ? this.state.values.endTime : this.state.values.time;
    return (time) ? time.split(':')[where === 'hour' ? 0 : 1] : '';
  }

  setTime = (where = 'hour', value, name) => {
    const time = (name === 'end' ? this.state.values.endTime : this.state.values.time) || '00:00';
    const split = time.split(':');
    const hour = split[0];
    const minute = split[1];
    if (where === 'hour') {
      this.setValue(name === 'end' ? 'endTime' : 'time', `${value}:${minute}`);
    } else if (where === 'minute') {
      this.setValue(name === 'end' ? 'endTime' : 'time', `${hour}:${value}`);
    }
  }

  onInputChangeLimitCharacters = (e, { name, value, maxDigits }) => {
    this.onTextInputChange(e, { name, value, maxDigits });
  }

  onError = (errors) => {
    if (errors.name && this.nameView) {
      return this.nameView.scrollIntoView({ behavior: 'smooth' });
    }
    if (errors.time && this.timeView) {
      return this.timeView.scrollIntoView({ behavior: 'smooth' });
    }
    if (errors.hasvideocallLink && this.hasvideocallLinkView) {
      return this.hasvideocallLinkView.scrollIntoView({ behavior: 'smooth' });
    }
  }

  render() {
    const { values, errors } = this.state;
    const { i } = this.props;
    const lang = this.props.store.app.locale && this.props.store.app.locale.substring(0, 2);
    const hasVideoConference = (values.hasvideocallLink || values.videocallLink);

    const actionButtonsLeft = [<BetaButton
      data-action="cancel"
      round
      transparent
      floated="left"
      text={__('Cancel')}
      onClick={() => this.props.store.appends.pop()}
    />];
    const actionButtonsRight = [<BetaButton
      data-action="submit"
      round
      icon={{ name: 'check' }}
      text={i === null ? __('Add') : __('Save')}
      onClick={this.handleSubmit}
    />];

    return (
      <Form
        id="CommitmentForm" {...this.props} actionButtonsLeft={!this.isMobile() ? actionButtonsLeft : null} actionButtonsRight={!this.isMobile() ? actionButtonsRight : null}
        paddingHeader={this.isMobile()}
        header={this.isMobile() ? {
          title: '',
          onClose: () => this.props.store.appends.pop(),
          invertCloseButton: true,
          headerItem: <BetaButton
            data-action="submit"
            round
            text={i === null ? __('Add') : __('Save')}
            onClick={this.handleSubmit}
            style={{ height: '40px', width: '100px', justifyContent: 'center', display: 'flex' }}
          /> } : null}
        onSubmit={this.handleSubmit}
        style={this.isMobile() ? { paddingLeft: '24px', paddingRight: '24px' } : {}}
      >
        <div ref={(el) => { this.nameView = el; }}>
          <Form.Input
            label={__('Commitment title')}
            labelStyle={{ fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', marginBottom: '12px' }}
            placeholder={__('Type your commitment title')}
            name="name"
            red={errors && errors.name && errors.name !== 'Error'}
            value={values.name}
            autoFocus
            style={{ width: '100%' }}
            error={errors && errors.name && errors.name !== 'Error' ? __('Required field') : null}
            onChange={e => this.onInputChangeLimitCharacters(e, { name: 'name', value: e.target.value, maxDigits: 255 })}
          />
        </div>
        <div style={this.isMobile() ? { ...styles.checkboxMobile, margin: '24px 0px 24px 0px' } : styles.checkbox}>
          <Form.Field
            className="last-item"
          >
            <ColoredCheckbox
              radio
              radioChecked
              style={{ fontSize: '16px', display: 'flex' }}
              bold={false}
              label={__('Enable date')}
              name="hasDate"
              checked={values.hasDate}
              onClick={this.onHasDateChange}
              color="#084FFF"
              disabled={this.props.edit}
            />
          </Form.Field>
        </div>
        <div style={this.isMobile() ? { ...styles.checkboxMobile, marginBottom: '24px' } : { ...styles.checkbox, marginLeft: '15px' }}>
          <Form.Field
            className="last-item"
          >
            <ColoredCheckbox
              radio
              radioChecked
              style={{ fontSize: '16px', display: 'flex' }}
              bold={false}
              label={__('Enable Time')}
              name="hasTime"
              checked={values.hasDate ? values.hasTime : values.hasTime && values.hasDate}
              onClick={this.onHasTimeChange}
              color="#084FFF"
              disabled={!values.hasDate || this.props.edit}
            />
          </Form.Field>
        </div>
        { values.hasDate &&
          <Form.Group style={{ display: 'flex', flexDirection: this.isMobile() ? 'column' : 'row', marginLeft: '0px' }}>
            <Form.Group style={{ alignItems: 'flex-end', margin: this.isMobile() ? (values.hasTime ? '0px 0px 16px 0px' : '0px') : '0px 16px 0px 0px' }}>
              {values.hasDate &&
              (this.isMobile() ?
                <div
                  onClick={() => this.props.store.appends.push(<BetaModal
                    toast
                    id={'CommitmentDateToast'}
                    header={__('Date')}
                    closeOnRootNodeClick
                    invertCloseButton
                    scrolling
                    onClose={() => this.props.store.appends.pop()}
                    content={
                      <Calendar
                        withPadding
                        calendarStyles
                        style={{ width: '28.333%' }}
                        hasDate={values.hasDate}
                        selectedDt={values.date}
                        onSelect={(d) => { this.setValue('date', d.selectedDt); this.props.store.appends.pop(); }}
                      />
                    }
                  />)}
                  style={this.isMobile() ? { width: '100%' } : {}}
                  ref={(el) => { this.timeView = el; }}
                >
                  <Form.Input
                    label={__('Date')}
                    icon={{ after: { name: 'chevron down', style: { fontSize: '16px' } } }}
                    labelStyle={{ fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', marginBottom: '12px' }}
                    value={values.date ? utils.simpleDate(values.date, true, 'll', lang) : ''}
                    disabled={!values.hasDate}
                    error={errors.date}
                    readOnly
                    data-action="open-start-calendar"
                  />
                </div>
                :
                <Popup
                  trigger={<div ref={(el) => { this.timeView = el; }}><Form.Input
                    className="calendar-input"
                    label={__('Date')}
                    icon={{ after: { name: 'chevron down', style: { fontSize: '16px' } } }}
                    labelStyle={{ fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', marginBottom: '12px' }}
                    value={values.date ? utils.simpleDate(values.date, true, 'll', lang) : ''}
                    disabled={!values.hasDate}
                    error={errors.date}
                    data-action="open-start-calendar"
                  /></div>}
                  label={__('Title')}
                  content={<Calendar
                    calendarStyles
                    style={{ width: '28.333%' }}
                    hasDate={values.hasDate}
                    selectedDt={values.date}
                    onSelect={d => this.setValue('date', d.selectedDt)}
                  />}
                  position="right center"
                  hideOnScroll
                  on="click"
                  disabled={!values.hasDate}
                  isOpen={values.hasDate ? undefined : false}
                />
              )}

            </Form.Group>

            <Form.Group style={{ display: 'flex-end', alignItems: 'flex-end', marginBottom: '0px' }}>
              {values.hasTime && values.hasDate &&
              <Form.Dropdown
                className={'form-dropdown'}
                name="hour"
                label={__('Hour')}
                icon={'chevron down'}
                compact
                selection
                placeholder="--"
                value={this.getTime('hour')}
                disabled={!values.hasTime || !values.hasDate}
                options={timeOptions.hour}
                onChange={(e, { value }) => this.setTime('hour', value)}
              />
              }
              {values.hasTime && values.hasDate &&
              <Form.Dropdown
                className={'form-dropdown'}
                name="minute"
                icon={'chevron down'}
                compact
                selection
                placeholder="--"
                value={this.getTime('minute')}
                disabled={!values.hasTime || !values.hasDate}
                options={timeOptions.minute}
                onChange={(e, { value }) => this.setTime('minute', value)}
              />
              }
            </Form.Group>
          </Form.Group>
        }
        {errors.time ?
          <div className="error-warning-red" >
            <Icon data-value="error" name="exclamation circle" />
            {errors.time}
          </div>

          :
          null
        }
        <div style={{ marginBottom: '24px', marginTop: values.hasDate ? '24px' : '0px' }}>
          <Form.Field
            className="last-item"
          >
            <ColoredCheckbox
              radio
              radioChecked
              style={{ fontSize: '16px', display: 'flex' }}
              bold={false}
              label={__('Require confirmation')}
              name="noConfirm"
              checked={!values.noConfirm}
              onClick={() => {
                if (values.date) {
                  this.setValue('dateLimit', values.date);
                } else {
                  this.setValue('dateLimit', new Date(date.getTime() + (30 * 86400000)));
                }

                this.onInputChange(null, { name: 'noConfirm', checked: !values.noConfirm });
              }}
              color="#084FFF"
              disabled={this.props.edit}
            />
          </Form.Field>
        </div>
        {
          !values.noConfirm &&
            (this.isMobile() ?
              <Form.Group style={{ alignItems: 'flex-end', margin: '0px 0px 26px 0px' }}>
                <div
                  onClick={() => this.props.store.appends.push(<BetaModal
                    toast
                    id={'CommitmentConfirmDateToast'}
                    header={__('Deadline')}
                    closeOnRootNodeClick
                    invertCloseButton
                    scrolling
                    onClose={() => this.props.store.appends.pop()}
                    content={
                      <Calendar
                        calendarStyles
                        selectedDt={values.dateLimit}
                        onSelect={(d) => { this.setValue('dateLimit', d.selectedDt); this.props.store.appends.pop(); }}
                      />
                    }
                  />)}
                  style={this.isMobile() ? { width: '100%' } : {}}
                >
                  <Form.Input
                    error={errors.dateLimit}
                    label={__('Confirmation deadline')}
                    labelStyle={{ fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', marginBottom: '12px' }}
                    readOnly
                    icon={{ after: { name: 'chevron down', style: { fontSize: '16px' } } }}
                    value={utils.simpleDate(values.dateLimit, true, 'll', lang)}
                  />
                </div>
              </Form.Group>
              :
              <Form.Group style={{ alignItems: 'flex-start', margin: '0px 16px 26px 0px', flexDirection: 'column' }}>
                <div style={{ marginBottom: '12px', display: 'flex' }}>
                  <span style={{ fontWeight: 'bold', fontSize: '16px' }}>{__('Confirmation deadline')}</span>
                  <Popup
                    trigger={<Icon name="info circle" style={{ color: '#a0a0a0', marginTop: '-2px', marginLeft: '10px', fontSize: '20px', fontWeight: '300' }} />}
                    content={__('When you ask the recipients to confirm a commitment, the limit is until 11:59 pm on the chosen day. If the confirmation is chosen for the same day as a commitment with start time, its limit will be until that time.')}
                    style={{ textAlign: 'justify' }}
                    basic
                  />
                </div>
                <Popup
                  trigger={(
                    <div>
                      <Form.Input
                        className="calendar-input"
                        error={errors.dateLimit}
                        readOnly
                        icon={{ after: { name: 'chevron down', style: { fontSize: '16px' } } }}
                        value={utils.simpleDate(values.dateLimit, true, 'll', lang)}
                      />
                    </div>
                  )}
                  content={<Calendar
                    calendarStyles
                    selectedDt={values.dateLimit}
                    onSelect={d => this.setValue('dateLimit', d.selectedDt)}
                    maxDate={values.date}
                  />}
                  position="right center"
                  hideOnScroll
                  on="click"
                />
              </Form.Group>
            )
        }
        { // meet
          (this.props.store.currentOrganization
            && this.props.store.currentOrganization.features
            && this.props.store.currentOrganization.features.videoConference)
            || this.props.store.currentLocation.pathname.includes('admin/message') ? (
              <div style={{ marginBottom: '24px' }}>
                <Form.Field
                  className="last-item"
                >
                  <ColoredCheckbox
                    radio
                    radioChecked
                    style={{ fontSize: '16px', display: 'flex' }}
                    bold={false}
                    label={__('Add video conference')}
                    name="hasvideocallLink"
                    checked={hasVideoConference}
                    onClick={() => {
                      if (!hasVideoConference) {
                      // this.setValue('videocallLink', values.videocallLink.toLowerCase());
                        this.setValue('videocallLink', values.videocallLink);
                        this.setValue('videocallType', 'link');
                      } else {
                        this.setValue('videocallLink', null);
                        this.setValue('videocallType', null);
                        values.videocallLink = null;
                        values.videocallType = null;
                      }
                      this.onInputChange(null, { name: 'hasvideocallLink', checked: !hasVideoConference });
                    }}
                    color="#084FFF"
                  />
                </Form.Field>
              </div>

            ) : ([])
        }
        {
          hasVideoConference
            && ((this.props.store.currentOrganization
              && this.props.store.currentOrganization.features
              && this.props.store.currentOrganization.features.videoConference)
              || this.props.store.currentLocation.pathname.includes('admin/message')) ?
                <div ref={(el) => { this.hasvideocallLinkView = el; }}>
              <Form.Input
                    style={{ marginBottom: '24px' }}
                    autoCapitalize={false}
                    autoCorrect={false}
                    autoComplete={false}
                    type="email"
                    label={__('Add video conference')}
                    red={errors && errors.hasvideocallLink && errors.hasvideocallLink !== 'Error'}
                    labelStyle={{ fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', marginBottom: '12px' }}
                    placeholder={__('Enter the video conference link here ...')}
                    name="videocallLink"
                    value={values.videocallLink}
                    error={errors && errors.hasvideocallLink && errors.hasvideocallLink !== 'Error' ? errors.hasvideocallLink : null}
                    onChange={e => this.onInputChangeLimitCharacters(e, { name: 'videocallLink', value: e.target.value, maxDigits: 255 })}
                  />
            </div>
            : ([])
        }

        {/* meet - end */}
      </Form>
    );
  }
}
